import React from "react";
import { Container, Row, Col, Card, CardTitle, CardBody } from 'reactstrap'
import Formulario from './Formulario'

function Contacto() {
  return (
    <div id="contacto" className="section">
      <div
        className="contactus-1 section-image"
        style={{
          backgroundImage:
            "url(" + require("assets/images/contacto.jpg") + ")",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10">
              <Card className="card-contact no-transition">
                <CardTitle className="text-center" tag="h3">
                  Contáctanos
                </CardTitle>
                <Row>
                  <Col className="ml-auto" md="5">
                    <CardBody>
                      <div className="info info-horizontal">
                        <div className="icon icon-info">
                          <i className="nc-icon icon-mail" />
                        </div>
                        <div className="description">
                          <h4 className="info-title">Escríbenos</h4>
                            <div className="pt-2">
                              <a href="mailto:contacto@vistasdechiloe.cl">contacto@vistasdechiloe.cl</a>
                            </div>
                          
                            
                        </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon icon-danger">
                          <i className="nc-icon icon-phone" />
                        </div>
                        <div className="description">
                          <h4 className="info-title">Teléfono</h4>
                          <div>
                            <a href="tel:+56229140602">+56 2 2914 0602</a>
                          </div>
                          
                        </div>
                      </div>
                    </CardBody>
                  </Col>
                  <Col className="mr-auto" md="5">
                    <Formulario />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
export default Contacto

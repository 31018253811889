import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
import Logo from '../../assets/images/logo.png'

import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,

  NavLink
} from "reactstrap";
// core components

function ColorNavbar() {
  const [styleLogo, setStyleLogo] = React.useState({width:'150px'})
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setStyleLogo({width:'150px'})
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
        setStyleLogo({width:'150px'})
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  },[styleLogo]);
  
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top pt-0", navbarColor)}
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand"  className="py-0" to="/index" tag={Link}>
              <img style={styleLogo} src={Logo} alt="logo" />
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  El Proyecto
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/#reserva" tag={Link}>
                    Proyecto
                  </DropdownItem>
                  <DropdownItem to="/#caracteristicas" tag={Link}>
                    Características
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink to="/#masterplan" tag={Link}>
                  Masterplan 360º
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink to="/#galeria" tag={Link}>
                  Galeria
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink to="/#video" tag={Link}>
                  Video
                </NavLink>
              </NavItem>
              <NavItem >
                <NavLink to="/#ubicacion" tag={Link}>
                  Ubicación
                </NavLink>
              </NavItem>
              <NavItem >
                <Button
                  className="btn-round"
                  color="danger"
                  to="/#contacto" 
                  tag={Link}
                  
                >
                  <i className="nc-icon nc-phone" /> Contacto
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ColorNavbar;

import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import ImgProyecto from '../../assets/images/proyecto.jpg'
function Proyecto(){
  return(
    <>
      <ElProyecto/>
      <Caracteristicas />
    </>
  )
}
export default Proyecto

function ElProyecto(){
  return(
    <div id="reserva" className="section pb-4">
      <Container>
        <Row className="justify-content-center">
          <h2 className="title">El Proyecto</h2>
        </Row>
        <Row className="py-3">
          <Col className="text-center" sm="12" md="6">
            <img style={{maxWidth:'420px', width:'420px'}} src={ImgProyecto} alt="img-proyecto"/>
          </Col>
          <Col md="6">
            <div className="pl-sm-3">
              <h3 className="title mt-0 pt-sm-4">Parcela chiloé</h3>
              <p style={{fontSize:'1.15rem', fontWeight:'400'}}>
                Un total de 14 parcelas dan vida al Proyecto “Parcela chiloé”.  Su nombre lo dice todo. 
                Todas las parcelas tienen una gran vista al mar, con lomajes suaves y bosque nativo, 
                que permiten conectar con el verdadero sentido de la vida y la naturaleza.  

                La ubicación de “Parcela chiloé” es la zona de San José, en Isla Tranqui, 
                a solo 20 minutos de Queilen y a 1 hora de Castro.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
function Caracteristicas(){
  return(
    <div id="caracteristicas">
      <div
          className="features-5 section-image"
          style={{
            backgroundImage:
              "url(" +
              require("assets/images/caracteristicas.jpg") +
              ")",
          }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto">
                <h2 className="title text-center pt-0">
                  Características
                </h2>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-road-2" />
                  </div>
                  <h4 className="title">Camino interior estabilizado y compactado.</h4>
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-drop-2" />
                  </div>
                  <h4 className="title">Factibilidad de agua de pozo y postación de luz para cada sitio.</h4>
                </div>
              </Col>
            </Row>
            <Row className="bottom-line">
              <Col className="ml-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-wifi" />
                  </div>
                  <h4 className="title">Excelente conexión de telefonía e internet.</h4> 
                </div>
              </Col>
              <Col className="mr-auto" sm="5">
                <div className="info">
                  <div className="icon">
                    <i aria-hidden={true} className="nc-icon icon-i-lock" />
                  </div>
                  <h4 className="title">Portón de acceso. </h4>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
    </div>
  )
}
import React from "react"
import ColorNavbar from "../components/Navbars/ColorNavbar.js"
import HeaderApp from "../components/HeaderApp"
import Proyecto from '../components/Proyecto'
import Masterplan from '../components/Masterplan'
import Video from '../components/Video'
import Galeria from '../components/Galeria'
import Ubicacion from '../components/Ubicacion'
import Contacto from '../components/Contacto'

function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  React.useEffect(() => {
    document.body.classList.add("section-page");
    
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });
    }
    return function cleanup() {
      document.body.classList.remove("section-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <HeaderApp />
      <Proyecto />
      <Masterplan />
      <Video />
      <Galeria />
      <Ubicacion />
      <Contacto />
    </>
  );
}

export default Index;
